/*
 * @Author: jason
 * @Date: 2024-10-24 16:33:18
 * @LastEditTime: 2025-01-06 10:15:19
 * @LastEditors: jason
 * @Description:
 * @FilePath: \lonsdor-center-backend\src\store\modules\fonticon.ts
 *
 */
import { defineStore } from "pinia";
import { store } from "../utils";
import axios from "axios";
import { loadFontIconCSS, loadFontIconJS } from "@/utils/func";
import { resourceIconLibraryListApi } from "@/api/resourceManagement/aliIconLibrary";

export type glyphsItem = {
  font_class: string;
  icon_id: string;
  name: string;
  unicode: string;
  unicode_decimal: string;
};

export type IconDataType = {
  css_prefix_text: string;
  description: string;
  font_family: string;
  id: string;
  name: string;
  glyphs: glyphsItem[];
};

export enum AliIconUseType {
  FONT_CLASS = "font_class",
  SYMBOL = "symbol"
}

export type IconLibrary = {
  name: string;
  key: string;
  use: AliIconUseType;
  prefix?: string;
  iconData?: IconDataType;
};

type StateType = {
  iconData: IconLibrary[];
};

export const getIconUrl = (key: string) => {
  return `https://at.alicdn.com/t/c/${key}.json?spm=a313x.manage_type_myprojects.i1.41.1bda3a81pUyWFn&file=${key}.json`;
};

// 阿里图标库的link标签的class
const iconLinkClass = "if-static";
// 移除阿里图标库的link标签
const removeIconLink = clsName => {
  let links = document.querySelectorAll(`.${clsName}`);
  links.forEach(item => {
    item.remove();
  });
};

// 加载对应的文件
const loadIconFile = (use: AliIconUseType, key: string) => {
  if (use === AliIconUseType.SYMBOL) {
    let url = `//at.alicdn.com/t/c/${key}.js`;
    loadFontIconJS(url, { classNames: [iconLinkClass] });
  } else if (use === AliIconUseType.FONT_CLASS) {
    let url = `//at.alicdn.com/t/c/${key}.css`;
    loadFontIconCSS(url, { classNames: [iconLinkClass] });
  }
};

export const getInitIconData = () => {
  return {
    css_prefix_text: "",
    description: "",
    font_family: "",
    id: "",
    name: "",
    glyphs: []
  };
};

export const useFonticonStore = defineStore({
  id: "fonticon",
  state: (): StateType => ({
    iconData: []
  }),
  actions: {
    async getIconLibrary() {
      let res = await resourceIconLibraryListApi(
        {},
        {
          customOption: {
            notErrorMsg: true
          }
        }
      );
      console.log("阿里图标库列表(store)");
      console.log(res);

      if (res.code === 0) {
        this.iconData = res.data;
        this.getIconDataFn();
      }
    },
    async getIconDataFn() {
      let iconFetchs = this.iconData.map(item =>
        axios.get(getIconUrl(item.key))
      );

      let resSuccess = await Promise.allSettled(iconFetchs);
      console.log("获取的图标数据");
      console.log(resSuccess);

      if (resSuccess) {
        removeIconLink(iconLinkClass);
        this.iconData = this.iconData.map((item, index) => {
          if (resSuccess[index].status === "fulfilled") {
            let data = resSuccess[index].value.data;
            item.iconData = data;
            item.prefix = data.css_prefix_text;
            // 加载对应的文件
            loadIconFile(item.use, item.key);
          } else {
            item.iconData = getInitIconData();
          }
          return item;
        });
      }
    }
  }
});

export function useFonticonStoreHook() {
  return useFonticonStore(store);
}
