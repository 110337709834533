/*
 * @Author: jason
 * @Date: 2024-11-06 15:58:20
 * @LastEditTime: 2025-01-03 04:05:01
 * @LastEditors: jason
 * @Description:
 * @FilePath: \lonsdor-center-backend\src\main.ts
 *
 */

import App from "./App.vue";
import router from "./router";
import { setupStore } from "@/store";
import { useI18n } from "@/plugins/i18n";
import { getPlatformConfig } from "./config";
import { MotionPlugin } from "@vueuse/motion";
// import { useEcharts } from "@/plugins/echarts";
import { createApp, type Directive } from "vue";
import { useElementPlus } from "@/plugins/elementPlus";
import { injectResponsiveStorage } from "@/utils/responsive";

import Table from "@pureadmin/table";
// import PureDescriptions from "@pureadmin/descriptions";

import Avue from "@smallwei/avue";
import { isEmpty } from "lodash";

// 引入重置样式
import "./style/reset.scss";
// 导入公共样式
import "./style/index.scss";
// 一定要在main.ts中导入tailwind.css，防止vite每次hmr都会请求src/style/index.scss整体css文件导致热更新慢的问题
import "./style/tailwind.css";
import "element-plus/dist/index.css";
// 导入字体图标 （平台自带）
import "./assets/iconfont/iconfont.js";
import "./assets/iconfont/iconfont.css";

// deault 已改为在线图标   iconfont2的品牌svg 已停用
// import "./assets/default/iconfont.js";
// import "./assets/default/iconfont2.js";
// import "./assets/default/iconfont.css";

// 自定义样式
import "./style/avue-curd.scss";

// 导入字体图标 （本地阿里云矢量图标）
import "./assets/iconfont-business/iconfont.js";
import "./assets/iconfont-business/iconfont.css";

import "jason-vue3-component/dist/style.css";

import "@smallwei/avue/lib/index.css";
import { menu1Conf } from "@/views/scm/demonstrate/helpMenu/components/myMenu";
import { Boot } from "@wangeditor/editor";
import emitter from "./views/scm/demonstrate/helpMenu/components/eventBus";
// 注册富文本编辑器自定义菜单
Boot.registerMenu(menu1Conf);

const app = createApp(App);
app.config.globalProperties.$bus = emitter;
// 自定义指令
import * as directives from "@/directives";
Object.keys(directives).forEach(key => {
  app.directive(key, (directives as { [key: string]: Directive })[key]);
});

// 全局注册@iconify/vue图标库
import {
  IconifyIconOffline,
  IconifyIconOnline,
  FontIcon
} from "./components/ReIcon";
app.component("IconifyIconOffline", IconifyIconOffline);
app.component("IconifyIconOnline", IconifyIconOnline);
app.component("FontIcon", FontIcon);

// 全局注册按钮级别权限组件
import { Auth, AuthPage } from "@/components/ReAuth";
app.component("Auth", Auth);
app.component("AuthPage", AuthPage);

/// 图片预览
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
app.use(VueViewer);

// 全局注册vue-tippy
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import VueTippy from "vue-tippy";
app.use(VueTippy);

import { JsonEditorPlugin } from "vue3-ts-jsoneditor";
app.use(JsonEditorPlugin, {
  componentName: "JsonEditor", // Default: 'JsonEditor',
  options: {}
});
app.use(Avue);

getPlatformConfig(app).then(async config => {
  setupStore(app);
  app.use(router);
  await router.isReady();
  injectResponsiveStorage(app, config);
  app.use(MotionPlugin).use(useI18n).use(useElementPlus).use(Table);
  // .use(PureDescriptions)
  // .use(useEcharts);
  app.mount("#app");
});
