/*
 * @Author: jason
 * @Date: 2024-12-14 14:39:13
 * @LastEditTime: 2025-01-03 03:00:26
 * @LastEditors: jason
 * @Description:
 * @FilePath: \lonsdor-center-backend\src\config\env.ts
 *
 */
/*
  env环境类
*/
class EnvConfig {
  // 判断是否使用本地路由
  get isLocalRoute() {
    return this.getBoolean("VITE_LOCAL_ROUTE");
  }

  // 请求接口的前缀
  get baseUrl() {
    return this.get("VITE_BASE_URL");
  }

  // 是否为开发环境
  get isDev() {
    return this.get("MODE");
  }

  /*
    将值转为 boolean
  */
  private getBoolean(key) {
    let value = this.get(key);
    if (value) {
      return JSON.parse(value);
    }
    return false;
  }

  /*
    将值转为 number
  */
  private getNumber(key) {
    let value = this.get(key);
    if (value) {
      return Number(value);
    }

    return "";
  }

  // 获取值
  private get(key) {
    return import.meta.env[key];
  }

  // 后端接口前缀
  get apiUrl() {
    return this.get("VITE_API_URL");
  }

  // 获取企业微信回调地址
  get wecomLoginUrl() {
    return this.get("VITE_WECOM_LOGIN_URL");
  }

  get ossDomain() {
    return this.get("VITE_OSS_CDN_DOMAIN");
  }

  get agentId() {
    return this.get("VITE_AGENT_ID");
  }
}

export default new EnvConfig();
