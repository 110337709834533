/*
 * @Author: jason
 * @Date: 2024-12-20 15:20:21
 * @LastEditTime: 2024-12-23 10:02:13
 * @LastEditors: jason
 * @Description:
 * @FilePath: \lonsdor-center-backend\src\api\resourceManagement\aliIconLibrary.ts
 *
 */
/* 阿里图标库      */

import { http } from "@/utils/http";
import type {
  IPageParam,
  IRemoveApiParams,
  IResponseResult
} from "@/api/types";
import type { AliIconUseType } from "@/store/modules/fonticon";
import type { PureHttpRequestConfig } from "@/utils/http/types";

export interface ResourceIconLibraryListParam extends IPageParam {}

export interface ResourceIconLibraryCreateParam {
  name: string;
  key: string;
  use: AliIconUseType;
  description: string;
}

export type ResourceIconLibraryUpdateParam = {
  id: number;
} & Partial<ResourceIconLibraryCreateParam>;

// 列表
export const resourceIconLibraryListApi = (
  params?: ResourceIconLibraryListParam,
  options?: PureHttpRequestConfig
) => {
  return http.get<IResponseResult, any>(
    "/resource/iconLibrary/list",
    {
      params
    },
    options
  );
};

// 插入
export const resourceIconLibraryCreateApi = (
  params: ResourceIconLibraryCreateParam
) => {
  return http.post<IResponseResult, any>("/resource/iconLibrary/create", {
    data: params
  });
};

// 更新
export const resourceIconLibraryUpdateApi = (
  params: ResourceIconLibraryUpdateParam
) => {
  return http.post<IResponseResult, any>("/resource/iconLibrary/update", {
    data: params
  });
};

// 删除
export const resourceIconLibraryDeleteApi = (params: IRemoveApiParams) => {
  return http.post<IResponseResult, any>("/resource/iconLibrary/delete", {
    data: params
  });
};
